import styled from "styled-components";
import { device } from "../../theme";
import { Row, Col } from "../../components/ui/wrapper";
import Heading from "../../components/ui/heading";

export const TechLogo = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 20px;
  }
`;

export const RightBox = styled.div``;

export const TechLogoText = styled.p`
  padding-bottom: 0px;
`;

export const TechlogoRow = styled(Row)`
  padding: 50px;
  border: none;
  border-top: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  display: flex;
  flex-direction: ${(props) => (props.i % 2 == 0 ? "row" : "row-reverse")};
  @media ${device.small} {
    padding: 20px;
    display: flex
    flex-direction: column;
    width: 100%;
  }
`;

export const TechlogoCol = styled(Col)`
  width: 50% !important;
  @media ${device.small} {
    width: 100% !important;
  }
`;

export const TechlogoHeading = styled(Heading)`
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  margine-bottom: 10px;
  @media ${device.small} {
    ont-size: 18px;
    line-height: 1;
    font-weight: 300;
  }
`;
