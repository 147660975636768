import React, {useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { useStaticQuery, graphql } from "gatsby"
import {Container, Row, Col, Box} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import {SectionWrap} from './bottom-banner.style'

const BottomBannerArea = ({sectionStyle, titleStyle}) =>  {
    const techBottomBannerQuery = useStaticQuery(graphql `
        query techBottomBannerQuery {
            file(relativePath: {eq: "images/banner/technology_bottom_banner.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    const imageData = techBottomBannerQuery.file.childImageSharp.fluid;
    const title = "Hello there";

    return(
        <Fragment>
            <SectionWrap fluid={imageData}>
               {/*  <Container>
                    <Row>
                        <Col lg={6} md={10} xs={11} ml="auto">
                            <Box>
                                {title && <Heading {...titleStyle}>{parse(title)}</Heading>}
                            </Box>
                        </Col>
                    </Row>
                </Container> */}
            </SectionWrap>
        </Fragment>
    )
}

BottomBannerArea.propTypes = {
    sectionStyle: PropTypes.object,
    titleStyle: PropTypes.object
}

BottomBannerArea.defaultProps = {
    sectionStyle: {

    },
    titleStyle: {
        lineHeight: 1.34,
        fontweight: 600,
        color: '#fff',
        mb: '65px'
    }
}

export default BottomBannerArea;