import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "../../components/ui/wrapper";
// import Heading from "../../components/ui/heading";
import {
  LeftBox,
  RightBox,
  TechLogoText,
  TechlogoRow,
  TechlogoCol,
  TechlogoHeading,
} from "./tech-logo-area.style";
import { TechLogoIconContainer } from "./tech-logo-icon-container";

const TechLogoContainer = ({ textStyle, flexStyle, props }) => {
  const techLogodataQuery = useStaticQuery(graphql`query TechLogodataQuery {
  logoContent: allTechLogoJson(filter: {id: {eq: "tech-logo-content"}}) {
    edges {
      node {
        data {
          id
          displayType
          title
          logoSet
          content
        }
      }
    }
  }
  allLogos: allTechLogoJson(filter: {id: {eq: "allTechLogos"}}) {
    edges {
      node {
        logos {
          id
          is_active
          title
          path
          category
          logo_image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                height: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          gray_image: logo_image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                height: 500
                placeholder: TRACED_SVG
                transformOptions: {grayscale: true}
                layout: CONSTRAINED
              )
            }
          }
          description
        }
      }
    }
  }
}
`);

  const techLogoContent = techLogodataQuery.logoContent.edges[0].node.data;
  const allTechLogosList = techLogodataQuery.allLogos.edges[0].node.logos;

  const allTechLogosMap = {};
  allTechLogosList.map((techLogo) => {
    allTechLogosMap[techLogo.id] = techLogo;
  });

  return (
    <Container>
      <Row>
        <Col lg={12}>
          {techLogoContent.map((techData, i) => {
            return (
              <TechlogoRow key={`${techData.id}-${i}`} {...{i}}>
                <TechlogoCol>
                  <LeftBox>
                    <TechlogoHeading >{techData.title}</TechlogoHeading>
                    <TechLogoText {...textStyle}>
                      {techData.content}
                    </TechLogoText>
                  </LeftBox>
                </TechlogoCol>
                <TechlogoCol>
                  <RightBox>
                    <TechLogoIconContainer
                      containerId={techData.id}
                      allTechLogosMap={allTechLogosMap}
                      logoSet={techData.logoSet}
                      displayType={techData.displayType}
                      {...props}
                    />
                  </RightBox>
                </TechlogoCol>
              </TechlogoRow>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};

TechLogoContainer.propTypes = {
  textStyle: PropTypes.object,
};

TechLogoContainer.defaultProps = {
  textStyle: {
    mb: "20px",
    fontSize: "18px",
  },
  flexStyle: {
    display: "flex",
    flexDirection: "row-reverse",
  },
};

export default TechLogoContainer;
