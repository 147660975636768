import React from "react";
import SectionOne from "../../containers/tech-logo/section-one";
import SectionTwo from "../../containers/tech-logo/section-two";
import SectionThree from "../../containers/tech-logo/section-three";
import SectionFour from "../../containers/tech-logo/section-four";

const TechLogoIconContainer = ({ containerId, allTechLogosMap, logoSet, displayType }) => {

  if(logoSet){
    const sliderData = logoSet.map((logoName, logoIndex) => {
      const mappedLogo = allTechLogosMap[logoName];

      if(!mappedLogo){
        return null;
      }

      const logoId = mappedLogo?.id;
      const uniqueEntryId = `${containerId}-${logoId}`
      const logoImage = mappedLogo?.logo_image?.childImageSharp;
      const grayImage = mappedLogo?.gray_image?.childImageSharp;
      const logoLinkPath = mappedLogo?.path;
      const logoTitle = mappedLogo?.title;
      const logoDescription = mappedLogo?.description;

      const logoData = {
        id: uniqueEntryId,
        img: logoImage,
        grayImg: grayImage,
        path: logoLinkPath,
        title: logoTitle,
        description:logoDescription
      }

      return logoData;
    });

    if (displayType == 1) {
      return <SectionOne sliderData={sliderData}/>
    }else if (displayType == 2) {
      return <SectionTwo sliderData={sliderData}/>
    }if (displayType == 3) {
      return <SectionThree sliderData={sliderData}/>
    }else{
      return <SectionFour sliderData={sliderData}/>
    }
  }else{
    return null;
  }
};

TechLogoIconContainer.propTypes = {};

TechLogoIconContainer.defaultProps = {};

export { TechLogoIconContainer };
