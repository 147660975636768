import React from "react";
import PropTypes from "prop-types";
import { SectionWrap } from "./section.style";
import SwiperSlider from "../../../components/ui/swiper";
import { Container, Row, Col } from "../../../components/ui/wrapper";

import TechLogo from "../../../components/ui/tech-logo";

const Section = ({ sliderData, slider, sliderStyle }) => {

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <SwiperSlider settings={slider} {...sliderStyle}>
              {sliderData.map((slide, index) => {
                return (
                  <div key={index} className="item">
                    <TechLogo 
                      id={`tech-logo-four-${slide.id}-${index}`}
                      brandImage={slide.img} 
                      path={slide.path}
                      title={slide.title}
                      description={slide.description}
                      layout={4} 
                    />
                  </div>
                );
              })}
            </SwiperSlider>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

Section.propTypes = {
  sliderData: PropTypes.array,
  slider: PropTypes.object
};

Section.defaultProps = {
  slider: {
    slidesPerView: 4,
    loop: true,
    speed: 3000,
    spaceBetween: 40,
    autoplay: {
      delay: 2500
    },
    breakpoints: {
      320: {
        slidesPerView: 2
      },
      575: {
        slidesPerView: 3
      },
      767: {
        slidesPerView: 4
      },
      991: {
        slidesPerView: 5
      },
      1499: {
        slidesPerView: 6
      }
    }
  },
  sliderStyle: {
    align: "center"
  }
};

export default Section;
