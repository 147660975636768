import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import {PageHeaderWrap} from './page-header.style'
import PageHeaderCustomBg from '../../../components/pageheadercustombg';


const PageHeader = ({pageContext, location, title, sectionStyle, titleStyle, descStyle}) => {
    const PageHeaderImageData = useStaticQuery(graphql`
        query TechPageHeaderQuery {
            file(relativePath: {eq: "images/banner/technology_top_banner.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        } 
    `);
    const imageData = PageHeaderImageData.file.childImageSharp.fluid;
    return <PageHeaderCustomBg
                pageContext={pageContext}
                location={location}
                title={title}
                fluidImageData={imageData}
                textColor="#4b1e79"
            />
    /* return (
        <PageHeaderWrap fluid={imageData}>
            <Container>
                <Row textalign="center">
                    <Col lg={8} ml="auto" mr="auto">
                        {title && <Heading {...titleStyle}>{title}</Heading>}
                        {desc && <Heading {...descStyle}>{desc}</Heading>}
                    </Col>
                </Row>
            </Container>
        </PageHeaderWrap>
    ) */
}

PageHeader.propTypes = {
    descStyle: PropTypes.object,
    titleStyle: PropTypes.object
}

PageHeader.defaultProps = {
    titleStyle: {
        as: 'h1',
        color: "#4b1e79",
        mb: '15px'
    },
    descStyle: {
        as: "h5",
        color: "#4b1e79",
        fontweight: 400
    }
}

export default PageHeader;