import React from 'react';
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Image from '../../image'
import {Text, BrandLogoWrap} from './tech-logo.style'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const WithTooltip = ({id, description,path,brandImg,hoverImg,title,...props}) => {
    const brandImage = getImage(brandImg);
    const hoverImage = getImage(hoverImg);

return (
    <OverlayTrigger
        key={`overlay-${id}`}
        placement='bottom'
        overlay={
        <Tooltip id={`overlay-tooltip-botton${id}`}>
            <strong>{description}</strong>.
        </Tooltip>
        }
    >
        <BrandLogoWrap {...props}>
            <a href={path} target="_blank" rel='noopener noreferrer'>
                <div className="brand-logo__image">
                    <GatsbyImage image={brandImage} alt={title}/>
                </div>
                {(props.layout === 1 || props.layout === 2) && 
                    <div className="brand-logo__image-hover">
                        <GatsbyImage image={hoverImage} alt={title}/>
                    </div>
                }
            </a>
            <Text>{title}</Text>
        </BrandLogoWrap>
    </OverlayTrigger>   
)
} 

const WithoutToolTip = ({id, description,path,brandImg,hoverImg,title,...props}) => {
    const brandImage = getImage(brandImg);
    const hoverImage = getImage(hoverImg);

    return(
        <BrandLogoWrap {...props}>
            <a href={path} target="_blank" rel='noopener noreferrer'>
                <div className="brand-logo__image">
                     <GatsbyImage image={brandImage} alt={title}/>
                </div>
                {(props.layout === 1 || props.layout === 2) && 
                    <div className="brand-logo__image-hover">
                         <GatsbyImage image={hoverImage} alt={title}/>
                    </div>
                }
                
            </a>
            <Text>{title}</Text>
        </BrandLogoWrap>
    )
}

const TechLogo = ({id, path, brandImage, hoverImage, title, description ,...props}) => {
     
    if(description && description.length){
        return(
            <WithTooltip id={id} description={description} path={path} brandImg={brandImage}  hoverImg={hoverImage} title={title} {...props} />
        )
    }
    else {
        return (
            <WithoutToolTip id={id} description={description} path={path} brandImg={brandImage}  hoverImg={hoverImage} title={title} {...props} />
        )
    }
 
}

TechLogo.propTypes = {
    path: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    hoverImage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

TechLogo.defaultProps = {
    layout: 1,
    path: '/',
    title: 'Brand Logo'
}

export default TechLogo; 