import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Header from "../containers/layout/header/header-one";
import Footer from "../containers/layout/footer/footer-one";
//import PageHeader from "../components/pageheader";
import CTA from "../containers/global/cta-area/section-one";
import SectionOne from "../containers/tech-logo/section-one";
import SectionTwo from "../containers/tech-logo/section-two";
import SectionThree from "../containers/tech-logo/section-three";
import SectionFour from "../containers/tech-logo/section-four";
import TechLogoContainer from "../containers/tech-logo/tech-logo-container";
import PageHeader from '../containers/technology/page-header'
import BottomBannerArea from "../containers/technology/bottom-banner";

const cta = {
  headingText: "We use modern mature technology to build <span>immersive</span> experiences",
  infoLink: {
      label: "Let's work together",
      path: "/free-consultation"
  }
}

const seo = {
  title: "Technology",
  description: "Technology",
  image: "/dark-logo.png",
  pathname: "/services",
};

const TechnologyPage = ({ pageContext, location }) => {
  return (
    <Layout location={location}>
    <SEO
      title={seo.title}
      description={seo.description}
      image={`${seo.image}`}
      pathname={seo.pathname}
    /> 
      <Header />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title="Technology"
      />
      <main className="site-wrapper-reveal">
        <TechLogoContainer />
        <BottomBannerArea/>
        {/* <CTA 
          headingText={cta.headingText}
          infoLink={cta.infoLink}
        /> */}
      </main>
      <Footer />
    </Layout>
  );
};

export default TechnologyPage;
